import type { THitListViewProps, Voucher } from './HitListView';
import type { THit } from '@vitafy/search-data-api-contracts';

const extractVoucherData = (promoText?: string | null): Voucher | null => {
  if (!promoText) {
    return null;
  }

  const matchDiscount = promoText.match(/(\d+)%/);
  const discount = matchDiscount ? Number(matchDiscount[1]) : undefined;

  const matchVoucherCode = promoText.match(/(?=.*[A-Z])[A-Z0-9]{3,}/);
  const voucherCode = matchVoucherCode ? matchVoucherCode[0] : undefined;

  if (!discount || !voucherCode) {
    return null;
  }

  return { discount, voucherCode };
};

export type ProductType = 'simple' | 'bundle' | 'configurable';

export const isProductType: (type?: string) => type is ProductType = (type?: string): type is ProductType =>
  ['bundle', 'simple', 'configurable'].includes(type || '');

type TReduceHitListViewProps = (product: THit) => THitListViewProps;

export const reduceHitListViewProps: TReduceHitListViewProps = ({
  badges,
  brand,
  debug,
  id,
  image,
  in_stock: inStock,
  name,
  prices,
  product_options: productOptions,
  promo_text,
  short_description: shortDescription,
  type_id: productType,
  url,
  totalReviews,
  rating
}) => ({
  badges,
  brand,
  ...(Object.keys(debug || {})?.length > 2 ? { debug } : {}),
  figure: {
    alt: '',
    image
  },
  id,
  inStock,
  name,
  prices,
  productOptions: productOptions || null,
  productType: isProductType(productType) ? productType : null,
  voucher: extractVoucherData(promo_text),
  shortDescription: shortDescription || '',
  url,
  reviews: {
    rating: (rating || 0) / 20,
    totalReviews
  }
});
