/** @jsxImportSource @emotion/react */
import Badge from '@stories/organisms/HitListView/Badge';

import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';

export type TBadges = {
  bestseller?: boolean | null;
  discount?: number | null;
  freeShipping?: boolean | null;
  free_text?: string | null;
  shortsale?: boolean | null;
  isNew?: boolean | null;
};

type TBadgesBarProps = {
  badges: TBadges;
  shadow?: boolean;
  cssProps?: Interpolation<Theme>;
};

const BadgesBar: React.FC<TBadgesBarProps> = ({
  badges: { bestseller, discount, isNew, freeShipping, free_text: freeText, shortsale } = {},
  shadow,
  cssProps
}) => {
  if (!bestseller && !discount && !freeShipping && !freeText && !shortsale && !isNew) {
    return null;
  }

  return (
    <div
      css={{
        alignItems: 'flex-start',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '.25rem',
        ...(cssProps as object)
      }}
    >
      {/* {shortsale === true && <Badge type="new" />} */}
      {(isNew || shortsale) && <Badge type="new" shadow={shadow} />}
      {bestseller === true && <Badge type="bestseller" shadow={shadow} />}
      {freeShipping === true && <Badge type="freeShipping" shadow={shadow} />}
      {typeof freeText === 'string' && freeText !== '' && (
        <Badge type="freeText" label={freeText} shadow={shadow} />
      )}
    </div>
  );
};

export default BadgesBar;
export type { TBadgesBarProps };
