/** @jsxImportSource @emotion/react */
import useTranslate from '@common/hooks/useTranslate';

export interface IBadgeProps {
  shadow?: boolean;
  type: 'bestseller' | 'new' | 'discount' | 'freeShipping' | 'freeText';
  label?: string;
}

const translations = {
  bestseller: {
    de_DE: 'Bestseller'
  },
  freeShipping: {
    de_DE: 'portofrei'
  },
  new: {
    de_DE: 'neu'
  }
};

const Badge: React.FC<IBadgeProps> = ({ type, label, shadow }) => {
  const translate = useTranslate(translations);

  if ((type === 'discount' || type === 'freeText') && !label) {
    return null;
  }
  return (
    <div
      id={`${type}Badge`}
      css={(t) => ({
        ...t.badge.core,
        ...t.badge[type],
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        ...(shadow && { boxShadow: '0 0 2px hsl(0 0% 0% / 20%)' })
      })}
    >
      <span css={(t) => ({ verticalAlign: t.badge.verticalAlign })}>{label || translate(type)}</span>
    </div>
  );
};

export default Badge;
