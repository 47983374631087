/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react';
import Image from 'next/image';
import { useContext, useEffect, useRef, useState } from 'react';

import { lineClamp } from '@common/cssUtilities';
import { useIntersection } from '@common/hooks/useIntersection';
import { trackGAAddToCart, trackGAProductClick, trackGAProductImpression } from '@common/tracking';
import { formatPrices, getLoaderWithAdditionalTransformations, makeUrlAbsolute } from '@common/utlis';
import AddToCartIconButton from '@stories/atoms/AddToCartIconButton';
import RatingStars from '@stories/atoms/RatingStars';
import BadgesBar from '@stories/organisms/HitListView/BadgesBar';
import Price from '@stories/organisms/HitListView/Price';
import { PageContext } from '@stories/templates/Context/pageContext';
import { media } from '@stories/theming/settings';
import { ProductWithOptionsContextProvider } from '@stories/utlis/context/ProductWithOptionsContext';

import { isProductType } from '../ReduceProps';

import type { TrackingData } from '@common/tracking';
import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';
import type { TBadges } from '@stories/organisms/HitListView/BadgesBar';
import type { PriceProps } from '@stories/organisms/HitListView/Price';
import type { TProductOptions } from '@stories/utlis/context/ProductWithOptionsContext';

const pickFirstTwoDefined = (obj: TBadges): TBadges => {
  const order = ['free_text', 'shortsale', 'isNew', 'bestseller', 'freeShipping'];

  return order
    .filter((key) => obj[key] !== undefined && obj[key] !== null && obj[key] !== '' && obj[key] !== 0)
    .slice(0, 2)
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {} as TBadges);
};

type HitCompactProps = {
  trackingParameters?: {
    name?: string;
    position?: number;
  };
} & {
  border?: boolean; // border variant has fixed height
  id: string;
  badges?: TBadges;
  inStock?: boolean;
  figure: {
    image: {
      path: string;
    };
    alt: string;
    sizes?: string;
  };
  name: string;
  prices: PriceProps;
  productOptions?: TProductOptions | null;
  reviews: { rating?: number; totalReviews?: number };
  type?: string;
  url: string;
  cssProps?: Interpolation<Theme>;
  sku?: string | null;
};

const linkCssFn = (t) => ({
  ...t.font.small,
  lineHeight: '120%',
  transition: 'color 80ms ease-in',
  ':after': {
    backgroundColor: 'hsl(0 0% 0% / 0%)',
    bottom: '0',
    content: "''",
    display: 'block',
    left: '0',
    position: 'absolute',
    right: '0',
    top: '0',
    transition: 'background-color 80ms ease-in',
    zIndex: 1
  },
  ':hover': {
    color: t.color.hover.copy
  },
  ':hover::after': {
    backgroundColor: 'hsl(0 0% 0% / 2%)'
  }
});

const HitCompact: React.FC<HitCompactProps> = ({
  id,
  border = true,
  badges,
  inStock,
  figure: {
    alt,
    image: { path },
    sizes
  },
  name,
  prices,
  productOptions: productOptionsProps,
  reviews,
  url,
  cssProps,
  sku,
  trackingParameters,
  type
}) => {
  const [productOptions, setProductOptions] = useState<TProductOptions>([]);
  const { numberOfLinesShownInProductHit } = useContext(PageContext);
  const hitRef = useRef<HTMLElement>(null);
  const { priceValue, price, base: baseprice } = formatPrices(prices);
  const theme = useTheme();

  useEffect(() => {
    if (productOptionsProps) {
      setProductOptions(productOptionsProps);
    }
  }, [productOptionsProps]);

  const trackingData: TrackingData = {
    brand: '',
    module: `Hit Compact - ${trackingParameters?.name}`,
    id,
    name,
    position: trackingParameters?.position,
    price: priceValue
  };

  useIntersection(hitRef, () => trackGAProductImpression(trackingData));

  return (
    <ProductWithOptionsContextProvider
      {...{
        productId: id,
        name,
        price,
        baseprice,
        productOptions,
        productType: isProductType(type) ? type : 'simple',
        productImage: path
      }}
    >
      <article
        ref={hitRef}
        css={() => ({
          minWidth: '220px',
          position: 'relative',
          filter: !inStock ? 'grayscale(1)' : 'none',
          ...(cssProps as object)
        })}
      >
        <div
          css={(t) => ({
            ...(border && {
              padding: '1rem .75rem',
              border: `1px solid ${t.color.grey.g92}`,
              borderRadius: '8px',
              [media('desktop')]: {
                padding: '1rem 1rem'
              }
            })
          })}
        >
          <div
            css={{
              aspectRatio: '1 / 1',
              borderRadius: '2px',
              position: 'relative',
              ...(!border && { backgroundColor: 'hsl(0 0% 92%)', padding: '.5rem' })
            }}
          >
            <div
              css={{
                aspectRatio: '1 / 1',
                position: 'relative'
              }}
            >
              <Image
                src={path}
                alt={alt}
                loader={border ? undefined : getLoaderWithAdditionalTransformations('e_bgremoval/')}
                fill={true}
                sizes={sizes ?? '200px'}
                css={{ display: 'block', width: '100%', height: 'auto' }}
              />
            </div>
          </div>
          {badges && (
            <BadgesBar
              badges={inStock ? pickFirstTwoDefined(badges) : { free_text: 'Bald wieder da' }}
              shadow={!border}
              cssProps={{ left: '8px', position: 'absolute', top: '-7px' }}
            />
          )}
          <div
            css={() => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              paddingTop: '.5rem'
            })}
          >
            <div
              css={{
                height: border
                  ? `calc(${theme.font.size.small}*1.2*${numberOfLinesShownInProductHit}) + 32px`
                  : 'auto'
              }}
            >
              <h3
                css={() => ({
                  display: 'block'
                })}
              >
                <a
                  href={makeUrlAbsolute(url)}
                  css={(t) => ({ ...linkCssFn(t), ...lineClamp(numberOfLinesShownInProductHit) })}
                  onClick={() => trackGAProductClick(trackingData)}
                >
                  {name}
                </a>
              </h3>
              {typeof reviews?.totalReviews !== 'undefined' &&
              reviews.totalReviews > 0 &&
              typeof reviews?.rating !== 'undefined' ? (
                <RatingStars
                  cssProps={{ margin: '.5rem 0rem' }}
                  ratingAverage={reviews.rating}
                  totalReviews={reviews.totalReviews}
                />
              ) : (
                <div css={{ height: '1rem', margin: '.5rem 0rem' }}></div>
              )}
            </div>
            <div
              css={() => ({
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 'auto'
              })}
            >
              <Price
                {...prices}
                fixedHeight={border}
                cssProps={{ fontSize: '22px', transform: 'scale(0.9)', transformOrigin: 'left' }}
              />
              {inStock && (
                <AddToCartIconButton
                  productId={id}
                  onClick={() => trackGAAddToCart({ id, name, sku, buttonVariant: 'hit compact' })}
                  compact
                  disabled={!inStock}
                />
              )}
            </div>
          </div>
        </div>
      </article>
    </ProductWithOptionsContextProvider>
  );
};

export default HitCompact;
export type { HitCompactProps };
